@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.main-logo {
  justify-content: flex-start;
  @include homefield.media-breakpoint-up('md') {
    flex-grow: 1;
  }

  @include homefield.media-breakpoint-up('xlg') {
    flex-grow: 0;
    margin: 0;
  }

  .main-logo-link {
    min-width: 117px;
    min-height: 48px;
    @media screen and (max-width: 992px) {
      text-align: center;
    }
    &:focus-visible {
      box-sizing: border-box;
      outline: 1px dashed var(--focus-color);
      outline-offset: 5px;
    }
  }
}
