.calia-react-header-scope.hmf-sm-theme {
  .slidein-nav-sm {
    .hmf-modal-container {
      width: 100% !important;
      background-color: var(--hmf-background-base-subtle);
      .hmf-modal-container-close {
        margin: var(--hmf-s) !important;
      }
    }
  }

  .slidein-nav {
    &-header {
      background-color: var(--hmf-gray50);
      height: 56px;
      border-bottom: 1px solid var(--hmf-gray200);

      &-back-btn {
        border: none;
        cursor: pointer;
        left: 20px;

        min-width: var(--hmf-xl) !important;
        padding-left: var(--hmf-s) !important;

        svg {
          width: var(--hmf-m);
          height: var(--hmf-m);
        }

        &:hover {
          border: none !important;
        }
      }

      &-close-btn {
        border: none;
        cursor: pointer;
        &:hover {
          border: none !important;
        }
      }
    }

    &-options {
      .option {
        background: var(--hmf-gray50);
        border: none;
        border-bottom: 1px solid var(--hmf-gray200);
        cursor: pointer;
        height: 56px;
        width: 100%;
        color: var(--hmf-gray900);

        &:hover {
          background-color: var(--hmf-gray100);
        }
        &:focus {
          outline: 1px dashed var(--hmf-gray500);
          outline-offset: -3px;
        }
        &:active {
          background-color: var(--hmf-gray200);
        }
      }
    }
  }
}
